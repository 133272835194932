import React from 'react'
import {Link, withPrefix} from 'gatsby'
import Layout from '../components/layouts/layout'
import HeadMeta from '../components/layouts/headmeta'
import ResourceOptInForm from '../components/resourceoptinform'

export default class GetHealthyRecipesPage extends React.Component {
  componentDidMount() {
    window.analytics.page('Landing', "Healthy Recipes")
  }

  render() {
    const { metadata, opt_in_form } = pageData

    return (
      <Layout>
        <HeadMeta metadata={metadata} />
        <section className="p-5 bg-success-lightest" >
          <div className="container text-center pt-5 mt-5">
            <h1 className="text-success-dark">Cook Healthy Meals For Your Family</h1>
            <p className="text-dark lead">Download this recipe book by Advanx Health team, which includes popular Malaysian cuisine, with a healthy twist!</p>
          </div>
          <div className="container-narrow my-5 py-5">
            <div className="row bg-success-light p-md-5">
              <div className="col-md-6">
                <img src={withPrefix('/img/recipe-front.jpg')} className="img-fluid-extend" alt="recipe"/>
              </div>
              <div className="col-md-6 py-5 py-md-0 text-center text-md-left">
                <h3 className="text-dark">Get easy & healthy Malaysia recipes, along with their nutritional value.</h3>
                <p className="text-dark">These fast & easy meals are perfect for a family.</p>
                <Link to="#cta" className="btn btn-success">Get My Copy</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="container text-center">
          <h2 className="">What's in the recipe book?</h2>
          <p className="px-5">These Malaysian food recipes are easy, yummy and most importantly slightly healthier than the original versions.</p>
          <div className="row">
            <div className="col-6 col-md-2 offset-md-2">
              <img src={withPrefix('/img/lp-icons/main-meal.png')} className="img-fluid px-5 py-4" style={{maxHeight: "8rem"}} alt="main meal"/>
              <h4>Main Meals</h4>
            </div>
            <div className="col-6 col-md-2">
              <img src={withPrefix('/img/lp-icons/side-dish.png')} className="img-fluid px-5 py-4" style={{maxHeight: "8rem"}} alt="side dish"/>
              <h4>Side Dishes</h4>
            </div>
            <div className="col-6 col-md-2">
              <img src={withPrefix('/img/lp-icons/snacks.png')} className="img-fluid px-5 py-4" style={{maxHeight: "8rem"}} alt="snacks"/>
              <h4>Snacks</h4>
            </div>
            <div className="col-6 col-md-2">
              <img src={withPrefix('/img/lp-icons/condiments.png')} className="img-fluid px-5 py-4" style={{maxHeight: "8rem"}} alt="condiments"/>
              <h4>Condiments</h4>
            </div>
          </div>
        </section>
        <section className="bg-success" id="cta">
          <div className="container-narrow text-center">
            <h3>Fill in your email and we'll send you a copy of the recipe book.</h3>
            <div className="row mt-4">
              <div className="col-md-6 offset-md-3">
                <ResourceOptInForm opt_in_form={opt_in_form} classes="bg-success text-center border-none" />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "Get Easy Healthy Recipes | Advanx Health",
    description: "Download this recipe book by Advanx Health team, which includes popular Malaysian cuisine, with a healthy twist!",
    image: "https://www.advanxhealth.com/img/share/healthy-recipe.png",
    url: "https://www.advanxhealth.com/get-healthy-recipes/"
  },
  opt_in_form: {
    header: "Get FREE Healthy Recipes",
    description: "Get FREE Healthy Recipes For Your Family",
    form_name: "lp-get-healthy-recipes",
    form_action: "/get-healthy-recipes/",
    form_interest: "healthy-recipes",
    form_submit: "Grab My Copy",
    mailchimp_group_id: "9df482a42a",
    mailchimp_group_list_id: "3afe94a2cd",
    mailchimp_tag_id: "32929"
  }
}
